import React, { useState } from 'react';
import {Alert,Button,Collapse } from 'antd';
import { json, useNavigate, } from 'react-router-dom';
import SubpayMent from '../subpayment';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';

const PaymentTransaction =(props)=>{
    const { percelloggedData,userConfig } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorMsg, seterrorMsg]=useState(null);

    const blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    const savePercelData = async (callback) => {
      let totalAmt =  localStorage.getItem('totalbillAmount');
      
      // dispatch(totaSenddata(formData));

      const phonePaydata ={
        name:percelloggedData?.senderInformation?.name,
        amount:JSON?.parse(totalAmt),
        mobileNumber:percelloggedData?.senderInformation?.phoneNumber,
        MID:"MID"+ Date.now(),
        transactionId:'T'+ Date.now()
      }
      try {
        const response = await axios.post('https://cred2payapilinux-egf8gabtfjdxcxce.southeastasia-01.azurewebsites.net/api/pay', phonePaydata, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        if (response?.status === 201 || response?.status === 200) {
          window.open(response.data?.url, "_blank");
          // navigate("/successpage")
        } else {
          // navigate("/layoutPercel/success");
          seterrorMsg(response?.data?.message);
        }
        seterrorMsg(response?.data?.message);
      } catch (error) {
        seterrorMsg(error?.response?.data?.message);
        console.error('Error creating parcel:', error);
      }
    };

    return (
      <>
        <div className="payment-transaction">
        {errorMsg && <Alert message="something went wrong please try again later !!" style={{color:'red'}}/>}
          <div className="summary-details">
            <h4>Sender Information</h4>
            <div className="d-flex summary-row">
              <p>
                Area of Destnation :{" "}
                {percelloggedData?.senderInformation?.areaOfDestination}
              </p>
              <p>
                Phone Numbe : {percelloggedData?.senderInformation?.phoneNumber}
              </p>
              <p>
                Source State :{" "}
                {percelloggedData?.senderInformation?.sourceState}
              </p>
              <p>Name : {percelloggedData?.senderInformation?.name}</p>
              <p>
                Pickup Date : {percelloggedData?.senderInformation?.pickupDate
                ? moment(percelloggedData.senderInformation.pickupDate)?.format('MM/DD/YYYY')
                : 'Not available'}
              </p>
              <p>Address : {percelloggedData?.senderInformation?.address}</p>
              <p>Land Mark : {percelloggedData?.senderInformation?.landmark}</p>
              <p>
                Identity Number:
                {percelloggedData?.senderInformation?.identityNumber}
              </p>
            </div>
            <h4>Receiver Information</h4>
            <div className="d-flex summary-row">
              <p>
                Area of Destnation :{" "}
                {percelloggedData?.recerverInformation?.areaOfDestination}
              </p>
              <p>
                Destination State :{" "}
                {percelloggedData?.recerverInformation?.destinationState}
              </p>
              <p>
                Country/City : {percelloggedData?.recerverInformation?.country}
              </p>
              <p>Name : {percelloggedData?.recerverInformation?.name}</p>
              <p>
                Phone Number :{" "}
                {percelloggedData?.recerverInformation?.phoneNumber}
              </p>
              <p>Delivary : {percelloggedData?.recerverInformation?.address}</p>
              <p>
                Land Mark : {percelloggedData?.recerverInformation?.landmark}
              </p>
              <p>FlatNo:{percelloggedData?.recerverInformation?.flatNo}</p>
            </div>
            <h4>Weight Information</h4>
            {percelloggedData?.weightInformation &&
            Array.isArray(percelloggedData.weightInformation) &&
            percelloggedData.weightInformation.length > 0 ? (
              percelloggedData.weightInformation.map((item, index) => (
                <div key={index} className="d-flex summary-row">
                  <p>Weight Calculation : {item?.weightCalculation}</p>
                  <p>Product : {item?.product}</p>
                  <p>Sub Category : {item?.subCategory}</p>
                  <p>Type of piece : {item?.typeOfPieces}</p>
                  <p>Actual Weight : {item?.actualWeight}</p>
                  <p>No.of pieces : {item?.noOfPieces}</p>
                  <p>Total Weight : {item?.amountOfKg}</p>
                  <p></p>
                </div>
              ))
            ) : (
              <p>No weight information available.</p>
            )}
          </div>
          <div className="payment-btn">
            <Button onClick={savePercelData}>Procced to Pay</Button>
            {/* <SubpayMent  savePercelData={savePercelData} totalPaymentAmount={percelloggedData?.totalPaymentAmount}/> */}
          </div>
        </div>
      </>
    );
}
export default PaymentTransaction;