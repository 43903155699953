import React, { useEffect ,useState,useRef} from "react";
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch,connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { percelLoggedData} from "../reducer";
const Successpage =(props)=>{
    const navigate = useNavigate();
    const { percelloggedData,userConfig } = useSelector(state => state.auth);
    const [errorMsg, seterrorMsg]=useState(null);
    const dispatch = useDispatch();
    const hasFetched = useRef(false);
    const logintoApp=()=>{
        navigate('/dashboard')
      }
      useEffect(()=>{
        if (!hasFetched.current) {
          savePercelData();
          hasFetched.current = true;
        }        
      },[])
      const savePercelData = async (callback) => {
        const data = localStorage.getItem('userCofigData');
        const userConfigdata = data ? JSON.parse(data) : null;  
        const formData = new FormData();
        formData.append('userId', userConfig?._id || userConfigdata?._id);
        formData.append('bookingType', percelloggedData?.bookingType);
        formData.append('senderInformation', JSON.stringify(percelloggedData?.senderInformation)); // Convert objects to strings
        formData.append('receiverInformation', JSON.stringify(percelloggedData?.recerverInformation));
        formData.append('weightInformation', JSON.stringify(percelloggedData?.weightInformation));
        formData.append('bookingSummary', JSON.stringify(percelloggedData?.bookingSummary));
        const base64Response = await fetch(percelloggedData?.image);
        const blob = await base64Response.blob();
        formData.append('image', blob, 'image.png');
        try {
            const response = await axios.post(`https://cred2payapilinux-egf8gabtfjdxcxce.southeastasia-01.azurewebsites.net/api/createparcel`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          
          if (response?.status === 201 || response?.status === 200) {
            localStorage.removeItem('formData');
            const updatedPercelLoggedData = {   
              senderInformation:{},           
              recerverInformation: {},
              weightInformation:[],
              bookingSummary:{}
            };
            props.percelLoggedData(updatedPercelLoggedData);
          } else {
            seterrorMsg(response?.data?.message);
          }
          seterrorMsg(response?.data?.message);
        } catch (error) {
          seterrorMsg(error?.response?.data?.message);
        }
      };
    return(<>
    <div className="">
        <div className="success-page">
      <CheckCircleOutlined className="success-icon" />
      <h1>Success!</h1>
      <p>Your transaction was completed successfully.</p>
      <Button type="primary" onClick={()=>logintoApp()}>Go to Dashboard</Button>
    </div></div>
    </>)
}
// export default Successpage;
const connectStateToProps = ({  }) => {
  return {  }
}
const connectDispatchToProps = dispatch => {
  return {
      percelLoggedData: (wallet) => {
        dispatch(percelLoggedData(wallet))
    },

  }
}
export default connect(connectStateToProps, connectDispatchToProps)(Successpage);